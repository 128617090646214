import React, { useContext, useMemo } from 'react'
import {
  LanguageProvider,
  langCompare,
  useLanguage
} from '@kogk/gatsby-plugin-i18n'
import { graphql, useStaticQuery } from 'gatsby'
import { useMediaQuery } from '@kogk/common'
import '@src/data/prismic-fragments/menu'
import '@src/data/prismic-fragments/strings'
import {
  breakpointMd,
  breakpointLg,
  breakpointXl
} from './breakpoints.module.scss'

const Ctx = React.createContext({
  altUrls: []
})

const useBootstrapBreakpoints = () => {
  const isSmallMobile = useMediaQuery(
    `screen and (max-width: ${breakpointMd - 1}px)`,
    false
  )
  const isMobile = useMediaQuery(
    `screen and (max-width: ${breakpointLg - 1}px)`,
    false
  )
  const isTablet = useMediaQuery(
    `screen and (min-width: ${breakpointLg}px) and (max-width: ${
      breakpointXl - 1
    }px)`,
    false
  )

  const isLgAndUp = useMediaQuery(
    `screen and (min-width: ${breakpointLg}px)`,
    false
  )

  const isSmallDesktop = useMediaQuery(
    `screen and (min-width: ${breakpointXl}px)`,
    false
  )

  // if it's eg. print or some other condition, a width check can be unreliable
  // so we'll try to default to desktop
  const isDesktop = !isMobile && !isTablet

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLgAndUp,
    isSmallMobile,
    isSmallDesktop
  }
}

export const GlobalDataProvider = ({ language, altUrls = [], children }) => {
  const results = useStaticQuery(query)
  const { availableLanguages } = useLanguage()

  const breakpoints = useBootstrapBreakpoints()

  const { translations, ...data } = useMemo(
    () => processData({ results, language, altUrls, availableLanguages }),
    [results, language, altUrls, availableLanguages]
  )

  return (
    <LanguageProvider
      data={{
        language,
        translations
      }}
    >
      <Ctx.Provider
        value={{
          ...data,
          ...breakpoints
        }}
      >
        {children}
      </Ctx.Provider>
    </LanguageProvider>
  )
}

export const useGlobalData = () => {
  return useContext(Ctx)
}

export const query = graphql`
  query {
    allPrismicAnnouncementBanner {
      nodes {
        ...fragmentPrismicAnnouncementBanner
      }
    }

    allPrismicAlertbanner {
      nodes {
        ...fragmentPrismicAlertbanner
      }
    }

    allLhSubcategory {
      edges {
        node {
          title
          subcategoryId
          id
        }
      }
    }

    allPrismicMenu {
      nodes {
        ...fragmentPrismicMenu
      }
    }

    allPrismicVerslun {
      nodes {
        ...fragmentPrismicVerslun
      }
    }

    allPrismicStrings {
      nodes {
        ...fragmentPrismicStrings
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const getAnnouncementBannerData = (announcementBannerNodes) => {
  const {
    data: { 
      show_hide: show, 
      text: { html: text }, 
      type 
    },
    last_publication_date: lastPublicationDate
  } = announcementBannerNodes[0];  

  return {
    show,
    text,
    type,
    lastPublicationDate
  }
}

const makeMenu = (results, id) => {
  const menu = results.find(m => m.prismicId === id)

  if (!menu) {
    throw new Error(`cant find menu with id ${id}`)
  }

  const {
    data: { nav_links: items }
  } = menu

  return items
    .filter(item => !item.slug.isBroken)
    .map(({ link_name: title, slug, sub_menu: subMenu }) => {
      return {
        title: title.text,
        subMenu: extractSubMenu(subMenu, results),
        url: slug.text
      }
    })
}

const extractSubMenu = (subMenu, results) => {
  const isNormalMenu = subMenu.id && subMenu.type === 'menu'

  return isNormalMenu ? makeMenu(results, subMenu.id) : []
}

const processData = ({ results, language, altUrls, availableLanguages }) => {
  const {
    allPrismicAnnouncementBanner: { nodes: announcementBannerNodes },
    allPrismicMenu: { nodes: menuNodes },
    allPrismicStrings: { nodes: translationNodes },
    allPrismicVerslun: { nodes: verslunNodes },
    site: {
      siteMetadata: { siteUrl }
    }
  } = results

  const subMenu = menuNodes
    .filter(menu => langCompare(menu.lang, language))
    .find(menu => menu.data.menu_name === 'submenu')

  const mainMenu = menuNodes
    .filter(menu => langCompare(menu.lang, language))
    .find(menu => menu.data.menu_name === 'mainmenu')

  if (!mainMenu) {
    throw new Error('mainmenu not found')
  }

  return {
    verslanir: getAllVerslanir(verslunNodes),
    translations: makeTranslations(translationNodes),
    mainMenu: makeMenu(menuNodes, mainMenu.prismicId),
    langMenu: makeLangMenu(language, availableLanguages, altUrls),
    siteUrl,
    subMenu: makeMenu(menuNodes, subMenu.prismicId),
    announcementBannerData: getAnnouncementBannerData(announcementBannerNodes)
  }
}

const getAllVerslanir = verslanir => {
  return verslanir.map(item => {
    const {
      address: { text: address },
      lyfsoluleyfishafi: { text: lyfsoluleyfishafi },
      email: { text: email },
      store_name: { text: storeName },
      kortahlekkur: { url: kort },
      fyrirtaeki
    } = item.data

    const thjonusta = item.data.thjonusta_i_bodi.map(i => {
      return i.name.text
    })

    const simanumer = item.data.simanumer.map(i => {
      const {
        name: { text: nafn },
        numer: { text: numer }
      } = i

      return { nafn, numer }
    })

    const opnunarTimar = item.data.opnunar_timar.map(o => {
      const {
        dagur: { text: dagur },
        timi: { text: timi }
      } = o
      return { dagur, timi }
    })

    return {
      address,
      lyfsoluleyfishafi,
      thjonusta,
      storeName,
      simanumer,
      kort,
      opnunarTimar,
      email,
      fyrirtaeki
    }
  })
}

const makeLangMenu = (language, availableLanguages, altUrls) => {
  return [
    {
      title: language,
      subMenu: availableLanguages.map(l => {
        const found = altUrls.find(entry => langCompare(entry.lang, l))

        return {
          title: l,
          // todo: find some way of figuring out the frontpage url for this lang
          url: found ? found.url : '/'
        }
      })
    }
  ]
}

/**
 * takes a list of translations from the prismic query and turns it into a data
 * type compatible with our strings in the i18n plugin
 * @param {array} gatsby node list
 */
const makeTranslations = nodes => {
  return nodes.reduce((acc, node) => {
    const {
      lang,
      data: { group_name: groupName, strings }
    } = node

    const langKey = lang.substring(0, 2)

    const keyedStrings = strings.reduce((acc, { key, value: { text } }) => {
      acc[key] = text

      return acc
    }, {})

    return {
      ...acc,
      [langKey]: {
        ...(acc[langKey] || {}),
        [groupName]: keyedStrings
      }
    }
  }, {})
}
